import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PDFDocument } from 'pdf-lib';
import QRCode from 'qrcode';
import QRCodeReact from 'qrcode.react';
import FileSaver from 'file-saver';
import { LuAlertTriangle } from "react-icons/lu";
import HeaderProduct from "./components/headerproduct";
import { FaCopy } from "react-icons/fa6";
const crypto = require('crypto');
window.Buffer = window.Buffer || require("buffer").Buffer;


function InputID() {
    let { hodlID } = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [data, setData] = useState(null);
    const [idHODL, setIdHODL] = useState("");
    const [txID, setTxID] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [productImage, setProductImage] = useState("");
    const [productName, setProductName] = useState("");
    const [totalSupply, setTotalSupply] = useState(0);
    const [isIOS, setIsIOS] = useState(false); // State to check if the user is on iOS
    useEffect(() => {
        // Function to detect if the user is on iOS
        const detectIOS = () => {
            return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
        };

        // Update the isIOS state
        setIsIOS(detectIOS());


            const fetchData = async () => {
                try {
                    // Fetch usernft data to get the product_id
                    const hodlResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata`);
                    const hodlData = await hodlResponse.json();
                    const hodlItem = hodlData.find((item: { id: { toString: () => string | undefined; }; }) => item.id.toString() === hodlID);
                    
                    if (hodlItem) {
                        setData(hodlItem);
                        setIdHODL(hodlItem.idhodl);

                        if (hodlItem.txid) {
                            setTxID(hodlItem.txid);
                            navigate(`/mintedNFT/${hodlID}`);
                        } else {
                            setTxID("");
                        }

                        // Fetch product data using the product_id from usernft
                        const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
                        const productData = await productResponse.json();
                        const productItem = productData.find((product: { product_id: string | undefined; }) => product.product_id === hodlItem.product_id);

                        if (productItem) {
                            const imageUrl = `https://drive.google.com/thumbnail?id=${productItem.image}&sz=w400`;
                            setProductImage(imageUrl); // Set the generated URL
                            setProductName(productItem.product_name);
                            
                            // Fetch total supply
                            const totalSupplyResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/totalsupply/${productItem.product_id}`);
                            const totalSupplyData = await totalSupplyResponse.json();
                            setTotalSupply(totalSupplyData.totalSupply);
                        } else {
                            console.error('Product not found');
                        }

                    } else {
                        setData(null);
                        setIdHODL("");
                        setTxID("");
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
    }, [hodlID, navigate]);

    const handleMint = async () => {
        if (!hodlID || !name) {
            alert("Please fill in all fields.");
            return;
        }

        setIsLoading(true);

        const originalDate = new Date().toISOString();

        // Format the date for PDF
        const options: Intl.DateTimeFormatOptions = {
            timeZone: 'Asia/Kuala_Lumpur', // UTC +8 timezone
            hour12: true, // Use 12-hour format with AM/PM
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timeZoneName: 'short' // This will show 'GMT+8'
        };
    
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date());
    
        const updatedData = {
            ID: hodlID,
            CertID: "",
            Date: originalDate, // Store original date format in the database
            TxID: "",
            Name: name,
            HODLID: idHODL,
        };
        const idHODLformated = idHODL.toString().padStart(3, '0');
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/mint`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    hodlID,
                    idHODLformated,
                    productName,
                }),
            });
            const result = await response.json();
            if (response.ok) {
                updatedData.TxID = result.txID;
                const networktype = result.networktype;
                const datalink = `https://explorer.solana.com/tx/${updatedData.TxID}?cluster=${networktype}`;
                const qrCodeDataUrl = await QRCode.toDataURL(datalink);
    
                try {
                    const pdfDoc = await PDFDocument.load(await fetch('/pdf/hodlID.pdf').then(res => res.arrayBuffer()));
    
                    const qrImage = await pdfDoc.embedPng(await fetch(qrCodeDataUrl).then(res => res.arrayBuffer()));
                    const qrImageDims = qrImage.scale(0.43);
    
                    const pages = pdfDoc.getPages();
                    const firstPage = pages[0];
                    firstPage.drawImage(qrImage, {
                        x: 448,
                        y: 351,
                        width: qrImageDims.width,
                        height: qrImageDims.height,
                    });
                    const HODLIDformated = updatedData.HODLID.toString().padStart(3, '0');
    
                    const form = pdfDoc.getForm();
                    form.getTextField('full_name').setText(updatedData.Name.toUpperCase());
                    form.getTextField('issue_date').setText(formattedDate); // Use formatted date for the PDF
                    form.getTextField('hodl_id').setText(HODLIDformated);
    
                    const txidDisplay = `${updatedData.TxID.slice(0, 12)}...${updatedData.TxID.slice(-12)}`; // Show 12 characters at the start and end
    
                    form.getTextField('txid').setText(txidDisplay);
                    form.getTextField('shirt_name').setText(productName.toUpperCase());
    
                    form.flatten();
                    const pdfBytes = await pdfDoc.save();
    
            // Generate the filename
            const fullName = updatedData.Name.replace(/\s+/g, '_').toUpperCase();
            const shirtName = productName.replace(/\s+/g, '_').toUpperCase();
            const hodlIDname = updatedData.HODLID.toString().padStart(3, '0');
            const filename = `${fullName}_${shirtName}_${hodlIDname}.pdf`;

            // FileSaver.saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), filename);

            const formData = new FormData();
            formData.append('file', new Blob([pdfBytes], { type: 'application/pdf' }), filename);

                    const uploadResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/uploadpdf`, {
                        method: 'POST',
                        body: formData,
                    });
    
                    const uploadResponseJson = await uploadResponse.json();
                    console.log('PDF uploaded successfully:', uploadResponseJson);
    
                    updatedData.CertID = uploadResponseJson.id;
    
                    const updateDataResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/updatehodldata/${updatedData.ID}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(updatedData),
                    });
    
                    const updateDataJson = await updateDataResponse.json();
                    console.log('Data updated successfully:', updateDataJson);
    
                    setIsLoading(false); // Stop loading animation
                    navigate(`/mintedNFT/${hodlID}`);
                } catch (error) {
                    console.error('Error generating or saving PDF:', error);
                    setIsLoading(false); // Stop loading animation on error
                }
            } else {
                console.error('Minting failed:', result.error);
                alert('Minting failed. Please try again.');
                setIsLoading(false); // Stop loading animation
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false); // Stop loading animation on error
        }
    };
        // Function to copy the link to the clipboard
        const handleCopyLink = () => {
            const link = `${process.env.REACT_APP_ORIGIN}/productid/${hodlID}`;
            navigator.clipboard.writeText(link)
                .then(() => {
                    alert('Link copied to clipboard!');
                })
                .catch((error) => {
                    console.error('Error copying link:', error);
                    alert('Failed to copy link.');
                });
        };

    const idhodlformated = idHODL.toString().padStart(3, '0');

    const totalsupplyformated = totalSupply.toString().padStart(3, '0');

    if (isIOS) {
        return (
            <div className="px-5 py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
               <div className=" justify-center flex h-full absolute top-0 left-0 w-full z-20 text-lg bg-black/20 text-black p-4 backdrop-blur-sm">
               <div className="bg-black rounded-lg p-5 my-auto py-auto">
<LuAlertTriangle className="text-orange h-8 w-8 text-center m-auto p-auto" />
<p className="text-orange uppercase font-bold text-center">Alert</p>
                    
                    <p className="text-white text-sm font-normal text-center">This feature is not available on iOS devices. Please use <span className=" font-semibold">Android</span> or <span className="font-semibold">Windows</span> to minting your shirt.</p>
                    <p className="text-white text-sm font-normal text-center">You can simply copy this link or scan this qr to minting your shirt on another device</p>
                    <p className="pt-5 text-white text-sm font-normal text-center">You can simply copy this link or Scan QR Code with another devices</p>
                
                <div className=" rounded-lg p-2 mt-4 text-white text-sm bg-darksecondarybg">
                {`${process.env.REACT_APP_ORIGIN}/productid/${hodlID}`}
                            <button
                                type="button"
                                className="ml-5 bg-gray-300 hover:bg-orange text-black font-bold py-2 px-4 rounded"
                                onClick={handleCopyLink}
                            >
                                <FaCopy />
                            </button>
                        </div>
      <div className="space-y-1">

        <div className="bg-white p-5 rounded-lg w-fit m-auto mt-5">
          <QRCodeReact
            value={`${process.env.REACT_APP_ORIGIN}/productid/${hodlID}`}
            size={100}
            bgColor="#ffffff"
            fgColor="#000000"
          />
        </div>
      </div>
                </div>
        <p></p>
    </div>
            <HeaderProduct src={productImage} name={productName} />
            <div className="space-y-2 pb-10">
                
                <div className="space-y-1">
                    <p className="text-orange uppercase font-bold">HODL ID</p>
                    <p className="text-white uppercase text-2xl">{idhodlformated} / {totalsupplyformated}</p>
                </div>
                <div>
                    <p className="text-orange uppercase font-bold">Enter Your Name</p>
                    <p className="text-white text-sm font-normal">This process can only be done once, and after this you can't change the owner's name.</p>
                </div>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Please Enter Your Name"
                    className="text-white w-full rounded-lg px-2 py-2 placeholder:italic bg-darksecondarybg placeholder:text-darkbg mt-20 focus:outline-none focus:border-orange focus:ring-orange focus:ring-1"
                />
            </div>
            <button
                onClick={handleMint}
                className={`bg-orange rounded-lg text-center text-black p-2 w-full ${isLoading ? 'opacity-50' : ''}`}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <div className="spinner"></div>
                        <span className="ml-2">Minting...</span>
                    </div>
                ) : (
                    "Mint"
                )}
            </button>
            </div>

        );
    }

    return (
        <div className="px-5 py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
            <HeaderProduct src={productImage} name={productName} />
            <div className="space-y-2 pb-10">
                
                <div className="space-y-1">
                    <p className="text-orange uppercase font-bold">HODL ID</p>
                    <p className="text-white uppercase text-2xl">{idhodlformated} / {totalsupplyformated}</p>
                </div>
                <div>
                    <p className="text-orange uppercase font-bold">Enter Your Name</p>
                    <p className="text-white text-sm font-normal">This process can only be done once, and after this you can't change the owner's name.</p>
                </div>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Please Enter Your Name"
                    className="text-white w-full rounded-lg px-2 py-2 placeholder:italic bg-darksecondarybg placeholder:text-darkbg mt-20 focus:outline-none focus:border-orange focus:ring-orange focus:ring-1"
                />
            </div>
            <button
                onClick={handleMint}
                className={`bg-orange rounded-lg text-center text-black p-2 w-full ${isLoading ? 'opacity-50' : ''}`}
                disabled={isLoading}
            >
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <div className="spinner"></div>
                        <span className="ml-2">Minting...</span>
                    </div>
                ) : (
                    "Mint"
                )}
            </button>
        </div>
    );
}

export default InputID;
