import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

interface ProductData {
    product_id: string;
    product_name: string;
    supply: number;
    image: string;
}

interface HolData {
    id: string;
    name: string;
    date: string;
    certid: string;
    txid: string;
    idhodl: string;
}

function ProductDetailsPage() {
    const { productID } = useParams<{ productID: string }>();
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [holData, setHolData] = useState<HolData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [additionalSupply, setAdditionalSupply] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch product data
                const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata/${productID}`);

                if (productResponse.ok) {
                    const productArray: ProductData[] = await productResponse.json();
                    if (productArray.length > 0) {
                        setProductData(productArray[0]);
                    } else {
                        console.error('No product found with the given ID');
                    }
                } else {
                    console.error('Failed to fetch product data');
                }

                // Fetch HODL data
                const holResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata/${productID}`);
                if (holResponse.ok) {
                    const hol: HolData[] = await holResponse.json();

                    // Sort holData by idhodl
                    const sortedHolData = hol.sort((a, b) => parseInt(a.idhodl, 10) - parseInt(b.idhodl, 10));
                    setHolData(sortedHolData);
                } else {
                    console.error('Failed to fetch HODL data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [productID]);

    const handleSubmit = async () => {
        if (!productData) {
            console.error('Product data not loaded yet');
            return;
        }

        setIsLoading(true);

        try {
            // Submit the product_id and additionalSupply
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/updateSupply`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id: productData.product_id,
                    additionalSupply: additionalSupply, // Send the additional supply amount
                }),
            });

            const result = await response.json();
            if (response.ok) {
                console.log(result.message);
            } else {
                console.error(result.error);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (id: string) => {
        const confirmed = window.confirm("Are you sure you want to delete this record?");
        if (!confirmed) return;

        setIsLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/deleteHodl/${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setHolData(holData.filter((item) => item.id !== id));
                console.log('Record deleted successfully');
            } else {
                const result = await response.json();
                console.error('Delete failed:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <div className="loader">Loading...</div>;
    }

    if (!productData) {
        return <div>Product not found</div>;
    }

    return (
        <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[1200px] mx-auto">
            <div className="space-y-2 pb-10">
                <h1 className="text-orange text-2xl uppercase font-bold pb-5">Product Details</h1>
            </div>
            
            {/* Product Data Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-darksecondarybg text-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-darkbg">Product ID</th>
                            <th className="py-2 px-4 border-b border-darkbg">Product Name</th>
                            <th className="py-2 px-4 border-b border-darkbg">Supply</th>
                            <th className="py-2 px-4 border-b border-darkbg">Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.product_id}</td>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.product_name}</td>
                            <td className="py-2 px-4 border-b border-darkbg">{productData.supply}</td>
                            <td className="py-2 px-4 border-b border-darkbg">
                                <img src={`https://drive.google.com/thumbnail?id=${productData.image}&sz=w400`} alt={productData.product_name} className="w-16 h-auto"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Additional Supply Input */}
            <div className="mt-4">
                <label htmlFor="additionalSupply" className="block text-white">Add Additional Supply:</label>
                <input
                    type="number"
                    id="additionalSupply"
                    className="mt-1 p-2 bg-darkbg text-white border border-darksecondarybg"
                    value={additionalSupply}
                    onChange={(e) => setAdditionalSupply(Number(e.target.value))}
                />
                <button 
                    className="mt-2 p-2 bg-orange text-white rounded"
                    onClick={handleSubmit}
                >
                    Update Supply
                </button>
            </div>

            {/* HODL Data Table */}
            <div className="overflow-x-auto mt-10">
                <table className="min-w-full bg-darksecondarybg text-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b border-darkbg">ID</th>
                            <th className="py-2 px-4 border-b border-darkbg">Name</th>
                            <th className="py-2 px-4 border-b border-darkbg">Date</th>
                            <th className="py-2 px-4 border-b border-darkbg">CerID</th>
                            <th className="py-2 px-4 border-b border-darkbg">TxID</th>
                            <th className="py-2 px-4 border-b border-darkbg">IDHODL</th>
                            <th className="py-2 px-4 border-b border-darkbg">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holData.map((item) => (
                            <tr key={item.id}>
                                <td className="py-2 px-4 border-b border-darkbg">{item.id}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.name}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.date}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.certid}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.txid}</td>
                                <td className="py-2 px-4 border-b border-darkbg">{item.idhodl}</td>
                                <td className="py-2 px-4 border-b border-darkbg">
                                    <button 
                                        className="text-red-500"
                                        onClick={() => handleDelete(item.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ProductDetailsPage;
