import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import { FaExpand, FaFileLines } from "react-icons/fa6";
import PoweredSolana from "./components/psolana";
import HeaderProduct from "./components/headerproduct";
import { RiShareBoxFill } from "react-icons/ri";

interface HodlData {
  id: number;
  certid: string;
  date: string;
  txid: string;
  name: string;
  idhodl: string;
  product_id?: string;
}

interface ProductData {
  product_id: string;
  image: string;
  product_name: string;
}

function RenderID() {
  const { hodlID } = useParams<{ hodlID: string }>();
  const [data, setData] = useState<HodlData | null>(null);
  const [productImage, setProductImage] = useState<string | null>(null);
  const [productName, setProductName] = useState<string | null>(null);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [iframeSrc, setIframeSrc] = useState<string>('');
  const [pdfURL, setpdfURL] = useState<string>('');



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch hodl data
        const res = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/hodldata`);
        const hodlData = await res.json();
        const hodlItem = hodlData.find((item: HodlData) => item.id.toString() === hodlID);
        setData(hodlItem || null);

        if (hodlItem) {
          if (hodlItem.certid.startsWith('https://gateway.pinata.cloud')) {
            setIframeSrc(`${hodlItem.certid}#toolbar=0&view=Fit&navpanes=0&scrollbar=0`);
            setpdfURL(`${hodlItem.certid}`)
          } else {
            setIframeSrc(`https://drive.google.com/file/d/${hodlItem.certid}/preview`);
            setpdfURL(`https://drive.google.com/file/d/${hodlItem.certid}/preview`)
          }
          

          if (hodlItem.product_id) {
            const productResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
            const productData = await productResponse.json();
            const productItem = productData.find((product: ProductData) => product.product_id === hodlItem.product_id);

            if (productItem) {
              const imageUrl = `https://drive.google.com/thumbnail?id=${productItem.image}&sz=w400`;
              setProductImage(imageUrl);
              setProductName(productItem.product_name);

              const totalSupplyResponse = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/totalsupply/${productItem.product_id}`);
              const totalSupplyData = await totalSupplyResponse.json();
              setTotalSupply(totalSupplyData.totalSupply);
            } else {
              console.error('Product not found');
            }
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [hodlID]);

  if (!data) {
    return <p>User not found</p>;
  }
  const handleOpenInNewTab = () => {
    const pdfUrllink = pdfURL;
    window.open(pdfUrllink, '_blank');
  };
  const txidDisplay = `${data.txid.slice(0, 6)}...${data.txid.slice(-6)}`;
  const originalDate = new Date(data.date);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Asia/Kuala_Lumpur',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZoneName: 'short'
  };

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(originalDate);
  const idhodlFormatted = data.idhodl.toString().padStart(3, '0');
  const totalSupplyFormatted = totalSupply.toString().padStart(3, '0');

  return (
    <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
      {productImage && productName && (
        <HeaderProduct src={productImage} name={productName} />
      )}
      <div className="space-y-2">
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Name</p>
          <p className="text-white uppercase">{data.name}</p>
        </div>
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Date Minted</p>
          <p className="text-white uppercase">{formattedDate}</p>
        </div>
        <div className="space-y-0">
          <p className="text-orange uppercase font-bold">Transaction ID</p>
          <div className="flex items-center space-x-2">
            <a
              className="text-white underline overflow-ellipsis text-center"
              href={`https://explorer.solana.com/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ maxWidth: "200px" }}
            >
              {txidDisplay}
            </a>
            <FaFileLines
              onClick={() => window.open(`https://explorer.solana.com/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`, '_blank')}
              className="text-white h-[24px] cursor-pointer"
            />
          </div>
        </div>
        <div className="space-y-1">
          <p className="text-orange uppercase font-bold">HODL ID</p>
          <p className="text-white uppercase text-2xl">{idhodlFormatted} / {totalSupplyFormatted}</p>
        </div>
      </div>

      <div className="space-y-1 relative">
        <p className="text-orange uppercase font-bold">PDF Viewer</p>
        <div className="relative w-[280px] h-[400px]">
          <iframe
            className="rounded-lg overflow-hidden"
            src={iframeSrc}
            width="280"
            height="400"
            allow="autoplay"
            style={{ pointerEvents: 'none' }}
          ></iframe>
          <button
            onClick={handleOpenInNewTab}
            className="absolute top-2 right-2 bg-black opacity-30 outline outline-5 outline-orange text-white p-3 rounded-full shadow-lg z-10"
            title="Open in New Tab"
          >
            <RiShareBoxFill className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="space-y-1">
        <p className="text-orange uppercase font-bold">QR Code</p>
        <div className="bg-white p-5 rounded-lg w-fit m-auto mt-5">
          <QRCode
            value={`${process.env.REACT_APP_ORIGIN}/mintednft/${hodlID}`}
            size={100}
            bgColor="#ffffff"
            fgColor="#000000"
          />
        </div>
      </div>

      <div className="bg-black outline outline-5 outline-orange items-center justify-center flex h-[60px] w-[60px] rounded-full fixed right-5 bottom-5">
        <FaFileLines
          onClick={() => window.open(`https://explorer.solana.com/tx/${data.txid}?cluster=${process.env.REACT_APP_NETWORK_EXPLORER}`, '_blank')}
          className="text-white h-[60px] cursor-pointer"
        />
      </div>
      <PoweredSolana />
    </div>
  );
}

export default RenderID;
