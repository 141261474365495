import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RenderID from './pages/renderid';
import Home from './pages/home';
import InputID from './pages/inputid';
import ErrorPage from './pages/errorpage';
import BlukGenerator from './pages/blukgenerator';
import Navbar from './pages/components/navbar';
import AdminPage from './pages/admin';
import ProductDetailsPage from './pages/productdetails';
import { ChakraProvider } from "@chakra-ui/react"
import WalletContextProvider from "../src/pages/contexts/walletcontext"

// Define the expected data structure
interface HodlData {
  id: number;
  certid: string;
  date: string;
  txid: string;
  name: string;
}

function App() {


  return (
    <ChakraProvider>
    <WalletContextProvider>
    <Router>
      <Navbar></Navbar>
      <Routes>
      <Route path='/admin' element={<AdminPage />} />
      <Route path='/admin/productdetails/:productID' element={<ProductDetailsPage />} />
       <Route path='/mintedNFT/:hodlID' element={<RenderID />} />
        <Route path='/admin/bulkgenerator' element={<BlukGenerator />} />
        <Route path='/' element={<Home />} />
        <Route path='/productid/:hodlID' element={<InputID />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Router>
    </WalletContextProvider>
  </ChakraProvider>
   
  );
}

export default App;
