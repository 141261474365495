import React, { useState } from "react";
import { PDFDocument, TextAlignment } from 'pdf-lib';
import QRCode from 'qrcode';
import FileSaver from 'file-saver';
import fontkit from '@pdf-lib/fontkit';
import imageCompression from 'browser-image-compression';
import { useNavigate } from "react-router-dom";

interface ProductData {
    product_id: string;
    product_name: string;
    supply: number;
    idhodl: string; // Ensure this field is present in the data
    image: string; // Add image field
}


function BulkGenerator() {
    const [product_name, setProductName] = useState("");
    const [supply, setSupply] = useState("");
    const navigate = useNavigate();
    const [productData, setProductData] = useState<ProductData[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [fileId, setFileId] = useState<string | null>(null);

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setSelectedImage(file);

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 400,
                useWebWorker: true,
            };

            try {
                const compressedFile = await imageCompression(file, options);
                const base64 = await imageCompression.getDataUrlFromFile(compressedFile);
                setImagePreview(base64);
            } catch (error) {
                console.error("Image compression failed:", error);
            }
        }
    };

    const handleImageUploadToServer = async (): Promise<string | null> => {
        if (!selectedImage) return null;

        const formData = new FormData();
        formData.append('file', selectedImage);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/uploadimage`, {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                console.log('Image uploaded to Google Drive with ID:', result.fileId);
                return result.fileId;
            } else {
                console.error(result.error);
                return null;
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            return null;
        }
    };
    


    
    const handleSubmit = async () => {
        setIsLoading(true);
        const product_id = product_name.toLowerCase().replace(/\s+/g, '_');

        // Upload image and get the file ID
        const uploadedFileId = await handleImageUploadToServer();
        if (!uploadedFileId) {
            console.error('Failed to upload image. Submission aborted.');
            setIsLoading(false);
            return;
        }

        try {
            // Submit the product data with the file ID
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/addproduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_id,
                    product_name,
                    supply: parseInt(supply),
                    image: uploadedFileId, // Ensure the file ID is submitted
                }),
            });

            const result = await response.json();
            if (response.ok) {
                console.log(result.message);
                generatePDF(result.idhodlArray);
            } else {
                console.error(result.error);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false);
            navigate(`/admin`);
        }
    };
    
    

    const generatePDF = async (idhodlArray: { id: string, idhodl: string }[]) => {
        try {
            console.log('Loading PDF template...');
            const pdfDoc = await PDFDocument.load(await fetch('/pdf/labelsolana.pdf').then(res => res.arrayBuffer()));
            console.log('PDF template loaded.');
    
            const newPdfDoc = await PDFDocument.create();
    
            // Helper function to parse and compare idhodl values
            const parseIdhodl = (idhodl: string) => {
                const trimmed = idhodl.trim();
                const parsed = parseInt(trimmed, 10);
                return isNaN(parsed) ? trimmed : parsed;
            };
    
            // Debugging: Log idhodl values before sorting
            console.log('Before sorting:', idhodlArray.map(item => item.idhodl));
    
            // Sort idhodlArray by numeric or lexicographic value of idhodl
            idhodlArray.sort((a, b) => {
                const parsedA = parseIdhodl(a.idhodl);
                const parsedB = parseIdhodl(b.idhodl);
                if (typeof parsedA === 'number' && typeof parsedB === 'number') {
                    return parsedA - parsedB;
                }
                return (parsedA as string).localeCompare(parsedB as string);
            });
    
            // Debugging: Log idhodl values after sorting
            console.log('After sorting:', idhodlArray.map(item => item.idhodl));
    
            for (let i = 0; i < idhodlArray.length; i++) {
                const user = idhodlArray[i];
                const [newPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
                newPdfDoc.addPage(newPage);
    
                const datalink = `${process.env.REACT_APP_ORIGIN}/productid/${user.id}`;
                console.log(`Generating QR code for: ${datalink}`);
                const qrCodeDataUrl = await QRCode.toDataURL(datalink);
                console.log('QR code generated.');
    
                const qrImage = await newPdfDoc.embedPng(await fetch(qrCodeDataUrl).then(res => res.arrayBuffer()));
                const qrImageDims = qrImage.scale(0.43);
    
                const pageWidth = newPage.getWidth();
                const pageHeight = newPage.getHeight() + 10;
                const x = (pageWidth - qrImageDims.width) / 2;
                const y = (pageHeight - qrImageDims.height) / 2;
    
                newPage.drawImage(qrImage, {
                    x: x,
                    y: y,
                    width: qrImageDims.width,
                    height: qrImageDims.height,
                });
    
                const url = '/font/mokoto.ttf';
                const fontBytes = await fetch(url).then(res => res.arrayBuffer());
    
                newPdfDoc.registerFontkit(fontkit);
                const montfont = await newPdfDoc.embedFont(fontBytes);
                const idhodlformated = user.idhodl.toString().padStart(3, '0');
                const form = newPdfDoc.getForm();
                const textField = form.createTextField(`hodl_id_${i}`);
                textField.setText(idhodlformated);
                textField.addToPage(newPage, { 
                    x: 7, 
                    y: 28,
                    borderWidth: 0,
                    height: 18,
                    width: 60,
                    font: montfont,
                });
                textField.setFontSize(18);
                textField.setAlignment(TextAlignment.Right);
    
                form.flatten();
            }
    
            const pdfBytes = await newPdfDoc.save();
            FileSaver.saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), "label.pdf");
            console.log('PDF saved as label.pdf');
    
        } catch (error) {
            console.error('Error generating or saving PDF:', error);
        }
    };
    const fetchProductData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_ORIGIN}/productdata`);
            const data: ProductData[] = await response.json();
            setProductData(data);
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };
    
    const handleGenerateDatabasePDF = () => {
        if (productData.length > 0) {
            generatePDF(productData.map(product => ({
                id: product.product_id, 
                idhodl: product.idhodl // Assuming 'idhodl' is part of the product data
            })));
        } else {
            console.log('No product data available');
        }
    };

    return (
        <div className="py-20 bg-darkbg space-y-4 items-center justify-center flex-col max-w-[450px] mx-auto">
            <div className="space-y-2 pb-10">
                <h1 className="text-orange text-2xl uppercase font-bold pb-5">Generate Bulk</h1>
                <div>
                    <p className="text-orange uppercase font-bold">Enter Product Name</p>
                </div>
                <input
                    className="text-white w-full rounded-lg px-2 py-2 placeholder:italic bg-darksecondarybg placeholder:text-darkbg mt-20 focus:outline-none focus:border-orange focus:ring-orange focus:ring-1"
                    type="text"
                    placeholder="Product Name"
                    value={product_name}
                    onChange={(e) => setProductName(e.target.value)}
                />
                <div>
                    <p className="text-orange uppercase font-bold">Total Supply</p>
                </div>
                <input
                    className="text-white w-full rounded-lg px-2 py-2 placeholder:italic bg-darksecondarybg placeholder:text-darkbg mt-20 focus:outline-none focus:border-orange focus:ring-orange focus:ring-1"
                    type="text"
                    placeholder="Total Supply"
                    value={supply}
                    onChange={(e) => setSupply(e.target.value)}
                />
                <div>
                    <p className="text-orange uppercase font-bold">Upload Image</p>
                </div>
                <input
                className="text-white bg-darksecondarybg outline-none rounded-full
                file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
      file:text-sm file:font-semibold
      file:bg-violet-50 file:text-black
      hover:file:bg-orange hover:file:text-white
                "
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                />
                {imagePreview && (
                    <div>
                        <p className="text-white">Image Preview:</p>
                        <img src={imagePreview} alt="Preview" className="w-full h-auto mt-4" />
                    </div>
                )}
            </div>
            <button className="bg-orange rounded-lg text-center text-black p-2 w-full" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? "Generating..." : "Submit"}
            </button>
            {isLoading && (
                <div className="flex justify-center items-center mt-4">
                    <div className="loader"></div>
                </div>
            )}
        </div>
    );
}

export default BulkGenerator;
